<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 순회점검 기본정보 -->
          <c-card title="LBL00001612" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :disabled="disabled"
                  :editable="editable && !isOld" 
                  type="edit" 
                  name="plantCd" 
                  v-model="patrol.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :editable="false"
                  codeGroupCd="PATROL_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="patrolTypeCd"
                  label="점검구분"
                  v-model="patrol.patrolTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="patrol.patrolTypeCd == 'PTC0000002'">
                <!-- 부서 -->
                <c-dept
                  required
                  :disabled="disabled"
                  :editable="editable && !isOld" 
                  label="점검 대상부서"
                  name="deptCd"
                  v-model="patrol.deptCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 순회일 -->
                <c-datepicker
                  required
                  type="month"
                  :disabled="disabled"
                  :editable="editable && !isOld" 
                  label="결재월"
                  name="patrolMonth"
                  v-model="patrol.patrolMonth"
                >
                </c-datepicker>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-show="isOld">
          <!-- 항목별 결과 목록 -->
          <!-- 항목별 결과 추가하세요. -->
          <c-table
            ref="grid"
            title="점검결과"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="grid.data"
            :filtering="false"
            :isExcelDown="false"
            :columnSetting="false"
            :usePaging="false"
            :hideBottom="true"
          >
            <template slot="table-chip">
              <q-chip
                v-for="(dept, idx) in patrol.depts"
                outline square
                :key="idx"
                :removable="false"
                :color="dept.selected ? 'primary' : 'grey-6'"
                text-color="white"
                icon="check"
                :selected.sync="dept.selected"
                :label="dept.vendorName"
                v-model="dept.check"
                @update:selected="state => datachange(state, dept, idx)"
              >
              </q-chip>
            </template>
            <template slot="table-button" v-if="Boolean(selectedPatrolDeptName)">
              <div>
                <q-btn-group outline>
                  <q-chip
                    outline square
                    color="orange"
                    text-color="white"
                    icon="person"
                    :label="selectedPatrolUserName + ' / ' + selectedPatrolDeptName"
                  >
                  </q-chip>
                </q-btn-group>
              </div>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrolDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolMonthId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      selectedPatrolDeptName: '',
      selectedPatrolUserName: '',
      patrol: {
        saiPatrolMonthId: '',  // 순회점검 월별결재 일련번호
        plantCd: null,  // 사업장 코드
        patrolMonth: '',  // 결재월
        depts: [],
        approvalStatusCd: '',
        patrolTypeCd: null,
        deptCd: '',
        deptName: '',
      },
      selectedDeptCd: '',
      selectedDeptName: '',
      editable: true,
      detailUrl: '',
      saveType: 'POST',
      resultItemUrl: '',
      saveUrl: '',
      checkUrl: '',
      checkUrl2: '',
      deleteUrl: '',
      updateUrl: '',
      deptUrl: '',
      isApproval: false,
      isSave: false,
      grid: {
        columns: [],
        data: [],
        height: '',
      },
    };
  },
  computed: {
    isOld() {
      return true;
    },
    disabled() {
      return true;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.grid.height = (window.innerHeight - 260) + 'px';
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.patrol.appr.get.url;
      this.saveUrl = transactionConfig.sai.patrol.appr.insert.url;
      this.deleteUrl = transactionConfig.sai.patrol.appr.delete.url;
      this.checkUrl = selectConfig.sai.patrol.appr.check.url;
      this.checkUrl2 = selectConfig.sai.patrol.appr.check2.url;
      this.deptUrl = selectConfig.sai.patrol.appr.dept.url;
      this.getDetail();
    },
    datachange(state, dept, idx) {
      this.selectedDeptCd = dept.vendorCd;
      this.selectedDeptName = dept.vendorName;
      this.selectedPatrolDeptName = dept.deptName;
      this.selectedPatrolUserName = dept.userName;
      this.$http.url = this.deptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        vendorCd: this.selectedDeptCd,
        patrolMonth: this.patrol.patrolMonth,
        patrolTypeCd: this.patrol.patrolTypeCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);

      if (state) {
        this.patrol.depts.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.patrol.depts[idx], 'selected', true)
      }
    },
    getDetail2() {
      this.$http.url = this.deptUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        deptCd: this.patrol.deptCd,
        patrolMonth: this.patrol.patrolMonth,
        patrolTypeCd: this.patrol.patrolTypeCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setColumns() {
      let _columns = [
        {
          name: 'patrolItemName',
          field: 'patrolItemName',
          // 점검항목
          label: '순회점검 항목',
          align: 'left',
          style: 'width:300px',
          sortable: false,
          fix: true,
        },
      ]
      let year = this.patrol.patrolMonth.substring(0, 4);
      let month = this.patrol.patrolMonth.substring(5);

      let days = [];
      let yearmonth = `${year}-${this.$_.padStart(month, 2, '0')}`;
      let yearmonthDays = this.$comm.moment(yearmonth).daysInMonth();

      for (let idx = 1; idx <= yearmonthDays; idx++) {
        let isHoliday = this.isHoliday(`${yearmonth}-${this.$_.padStart(idx, 2, '0')}`);
        days.push({
          name: 'day' + idx,
          field: 'day' + idx,
          key: 'day' + idx,
          label: idx,
          align: 'center',
          setHeader: true,
          style: 'width:35px',
          headerColor: isHoliday ? '#D9D9D9' : '#FFC000',
          tdColor: isHoliday ? '#D9D9D9' : null,
          headerType: 'link',
          sortable: false,
        })
      }
      this.grid.columns = this.$_.concat(_columns, days);

      if (this.patrol.depts.length > 0 || this.patrol.patrolTypeCd == 'PTC0000001') {
        this.selectedDeptCd = this.patrol.depts[0].vendorCd 
        this.datachange(false, {vendorCd: this.selectedDeptCd, vendorName: this.patrol.depts[0].vendorName, deptName: this.patrol.depts[0].deptName, userName: this.patrol.depts[0].userName }, 0);
      } else if (this.patrol.patrolTypeCd == 'PTC0000002') {
        this.getDetail2();
      }
    },
    isHoliday(day) {
      // 일 : 0
      // 토 : 6
      let dayOfWeek = this.$comm.moment(day).day();
      if ([0, 6].indexOf(dayOfWeek) > -1) {
        return true;
      } else {
        return false;
      }
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.detailUrl;
        this.$http.param = {saiPatrolMonthId: this.popupParam.saiPatrolMonthId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.patrol, _result.data);
          
          if (this.patrol.patrolTypeCd == 'PTC0000001') {
            this.patrol.depts.forEach((item) => {
              item.selected = false;
            })
          } else {
            this.patrol.depts = [];
          }
          this.$emit('setRegInfo', _result.data)
          this.setColumns();
        },);
      } else {
        this.patrol.patrolMonth = this.$comm.getThisMonth();
        this.patrol.patrolTypeCd = this.patrol.patrolTypeCd;
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.patrol.appr.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.patrol.appr.insert.url;
        this.saveType = 'POST';
      }
      let resultMsg = '';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.patrol.patrolTypeCd == 'PTC0000001') {
            this.$http.url = this.$format(this.checkUrl, this.patrol.plantCd, this.patrol.patrolMonth);
            resultMsg = '동일한 업체의 결재 월이 존재합니다.';
          } else {
            this.$http.url = this.$format(this.checkUrl2, this.patrol.plantCd, this.patrol.patrolMonth, this.patrol.deptCd);
            resultMsg = '동일한 대상부서의 결재 월이 존재합니다.';
          }
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.isOld || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.patrol.regUserId = this.$store.getters.user.userId
                  this.patrol.chgUserId = this.$store.getters.user.userId

                  this.isSave = !this.isSave
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: resultMsg,
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolMonthId', _result.data)
      }
      this.getDetail();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiPatrolMonthId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveDataAppr() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: '결재요청을 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    print() {
      let thisVue = this;
      this.$http.url = selectConfig.sai.patrol.appr.print.url;
      this.$http.param = {plantCd: this.patrol.plantCd, patrolMonth: this.patrol.patrolMonth}
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = '';
          if (this.patrol.patrolTypeCd == 'PTC0000001') {
            fileOrgNm = '안전관리점검일지_' + this.patrol.patrolMonth + '.xlsx';
          } else {
            fileOrgNm = '안전관리점검일지_' + this.patrol.deptName + '_' + this.patrol.patrolMonth + '.xlsx';
          }
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
